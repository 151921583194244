import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";

import TranslateToList from "./TranslateToList";
import QualityOutput from "./QualityOutput";
import { Link } from "gatsby";
import DetailAnalysis from "./DetailAnalysis";
import useDebounce from "./useDebounce";
import axios from "axios";
import { Languages } from "./LanguagesData";
import TranslateFromList from "./TranslateFormList";
import ResetButton from "assets/resetbutton.jpg";
import Toolinput from "components/commontools/InsertTool";

import ToolsBanner from "components/commontools/toolsBanner";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";

const QualityAnalysis = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [TransFromOpen, setTransFromOpen] = useState(false);
  const [TransToOpen, setTransToOpen] = useState(false);
  const [FromSearchInput, setFromSearchInput] = useState("");
  const [ToSearchInput, setToSearchInput] = useState("");
  const [sameLanguageError, setSameLanguageError] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const TranslateFromRef = useRef(null);
  const debouncedInputText = useDebounce(inputText, 2000);
  const debouncedOutputText = useDebounce(outputText, 2000);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  // const isProduction =
  //   process.env.GATSBY_ES_PREFIX === "en.prod."
  //     ? "https://api.machinetranslation.com"
  //     : "https://mtstage.bytequests.com";

  // console.log(isProduction, "isproduction");
  const { data } = useFetchLanguages();

  const TranslateFromstyles = {
    display: TransFromOpen ? "block" : "none",
  };
  const TranslateTostyles = {
    display: TransToOpen ? "block" : "none",
  };

  const handleTranslateFromInput = (lang, code) => {
    setInputLang(code);
    setTranslateFromInput(lang);
    setTransFromOpen(!TransFromOpen);
    setFromSearchInput("");
  };

  const handleTranslateToInput = (lang, code) => {
    setOutputLang(code);
    setTranslateToInput(lang);
    setTransToOpen(!TransToOpen);
    setToSearchInput("");
  };

  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectTargetLanguage(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      const languageName = Languages.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }

      const languageCode = response.data?.data?.language_probability?.name;
      setTranslateFromInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const detectTargetLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const languageTo = response.data?.data?.language_probability?.code;
      const languageToName = Languages.find(
        (lang) => lang?.code === languageTo
      );
      if (languageToName?.name) {
        setOutputLang(languageTo);
      }
      const languageCode = response.data?.data?.language_probability?.name;

      setTranslateToInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError(false);
    setShowOutput(false);

    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "translationQuality",
        source_text: inputText,
        translation: outputText,
        source_language_code: inputLang,
        target_language_code: outputLang,
      });

      setAnalysisResult(response?.result);
      setShowOutput(true);
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const filteredFromData = Languages.filter((item) => item.code !== outputLang);
  const filteredToData = Languages.filter((item) => item.code !== inputLang);

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;
  const placeholderText = (
    <span className="absolute top-0 left-0 pl-4 py-4 text-[15px] text-[#B4B4B4] pointer-events-none">
      Enter your <span className="font-semiboldb text-[#ff0000]">source</span>{" "}
      text here
    </span>
  );

  const handleReset = async () => {
    await handleClick();
  };
  const handleClearText = () => {
    setInputText("");
    setOutputText("");
    setInputLang("");
    setOutputLang("");
    setTranslateToInput("");
    setTranslateFromInput("");
    setAnalysisResult(null);
    setShowOutput(false);
    setWordCountInput(0);
    setWordCountOutput(0);
  };
  return (
    <div className="bg-[#F5F5F5]">
      {showDetailAnalysis ? (
        <>
          <DetailAnalysis
            setShowDetailAnalysis={setShowDetailAnalysis}
            analysisResult={analysisResult}
          />
        </>
      ) : (
        <>
          <div className="py-7">
            <ToolsBreadcrumb />
          </div>
          <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
            <ToolsBanner
              text="TRANSLATION QUALITY ASSURANCE TOOL"
              title="Identify and Resolve Quality Issues Quickly"
              description="  Enter your source text and its translation, and get detailed
              feedback on accuracy, fluency, and overall translation quality
              instantly."
            />
            <div className="lg:flex gap-3 mt-12">
              <div className="flex flex-col gap-3 w-full lg:w-[70%]">
                <div className="lg:flex items-center  rounded-lg shadow-lg ">
                  <Toolinput
                    lang={inputLang}
                    text={inputText}
                    wordCount={wordCountInput}
                    setText={setInputText}
                    setOutputText={setOutputText}
                    setInputLang={setInputLang}
                    setOutputLang={setOutputLang}
                    setTranslateToInput={setTranslateToInput}
                    setTranslateFromInput={setTranslateFromInput}
                    setAnalysisResult={setAnalysisResult}
                    setShowOutput={setShowOutput}
                    setWordCount={setWordCountInput}
                    iscleanBtn={true}
                    refDiv={refDiv}
                    result={analysisResult}
                    divHeight={divHeight}
                    handleClearText={handleClearText}
                    placeholder="Enter your source text here"
                  >
                    {" "}
                    <TranslateFromList
                      data={{ data: filteredFromData }}
                      TranslateFromRef={TranslateFromRef}
                      TransFromOpen={TransFromOpen}
                      transtalteFromInput={transtalteFromInput}
                      setTransFromOpen={setTransFromOpen}
                      TranslateFromstyles={TranslateFromstyles}
                      FromSearchInput={FromSearchInput}
                      setFromSearchInput={setFromSearchInput}
                      handleTranslateFromInput={handleTranslateFromInput}
                      sameLanguageError={sameLanguageError}
                      divHeight={divHeight}
                    />
                  </Toolinput>

                  <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                  <Toolinput
                    lang={outputLang}
                    text={outputText}
                    wordCount={wordCountOutput}
                    setText={setOutputText}
                    setWordCount={setWordCountOutput}
                    refDiv={refDiv}
                    result={analysisResult}
                    divHeight={divHeight}
                    placeholder="Enter your translated text here"
                    handleClearText={handleClearText}
                  >
                    <TranslateToList
                      data={{ data: filteredToData }}
                      TranslateToRef={TranslateFromRef}
                      TransToOpen={TransToOpen}
                      transtalteToInput={transtalteToInput}
                      setTransToOpen={setTransToOpen}
                      TranslateTostyles={TranslateTostyles}
                      ToSearchInput={ToSearchInput}
                      setToSearchInput={setToSearchInput}
                      handleTranslateToInput={handleTranslateToInput}
                      sameLanguageError={sameLanguageError}
                      divHeight={divHeight}
                    />
                  </Toolinput>
                </div>
              </div>

              <ToolOutput
                handleClick={handleClick}
                result={analysisResult}
                isButtonDisabled={isButtonDisabled}
                ResetButton={ResetButton}
                loading={loading}
                handleReset={handleReset}
                showOutput={showOutput}
                error={error}
                ref={refDiv}
                buttontext="Check quality"
                isQuality={true}
                text="  Your results will appear here. <br /> Please enter your texts and click 'Check quality' to begin."
              >
                <QualityOutput
                  setDivHeight={setDivHeight}
                  setShowDetailAnalysis={setShowDetailAnalysis}
                  analysisResult={analysisResult}
                  refDiv={refDiv}
                />
                <div className="bg-[#F0F5FF] p-3 rounded-lg flex items-center gap-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="38"
                    viewBox="0 0 19 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.14286 0C9.41473 0 9.66601 0.144874 9.80223 0.380163L18.1832 14.8564C18.3196 15.0921 18.3199 15.3827 18.1839 15.6187C18.0478 15.8546 17.7962 16 17.5238 16H0.761905C0.48954 16 0.237889 15.8546 0.101848 15.6187C-0.0341934 15.3827 -0.0339326 15.0921 0.102532 14.8564L8.48349 0.380163C8.6197 0.144874 8.87098 0 9.14286 0ZM2.08339 14.4762H16.2023L9.14286 2.28256L2.08339 14.4762Z"
                      fill="#9EBEFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.14255 5.33276C9.56334 5.33243 9.90473 5.67327 9.90507 6.09406L9.90811 9.90358C9.90845 10.3244 9.56761 10.6658 9.14682 10.6661C8.72603 10.6664 8.38464 10.3256 8.3843 9.9048L8.38126 6.09528C8.38092 5.67449 8.72176 5.3331 9.14255 5.33276ZM9.14316 11.428C9.56395 11.428 9.90507 11.7691 9.90507 12.1899V12.5709C9.90507 12.9916 9.56395 13.3328 9.14316 13.3328C8.72237 13.3328 8.38126 12.9916 8.38126 12.5709V12.1899C8.38126 11.7691 8.72237 11.428 9.14316 11.428Z"
                      fill="#9EBEFF"
                    />
                  </svg>
                  <p className="text-[12px] font-opensans font-normal text-[#6E6E6E]">
                    {" "}
                    This tool may make mistakes. Please review the results. Your
                    data is kept secure and private.
                  </p>
                </div>
              </ToolOutput>
            </div>
            <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
          Assisted by AI tools. Inaccuracies may occur.
          </p>
            {analysisResult && (
              <div className="bg-[#F0F5FF] p-6 rounded-lg mt-6 lg:flex justify-between gap-6">
                <div>
                  <h2 className="text-2xl font-primary text-[#0A2641] font-bold">
                    Need help translating?
                  </h2>
                  <p className="text-[#424242] pt-3 leading-6 font-base font-opensans ">
                    If your translation needs improvement, reach out to Tomedes
                    for expert translation, editing, and proofreading services.
                  </p>
                </div>
                <Link to="/contactus.php">
                  <button className="flex mt-4 md:mt-0 items-center gap-2 bg-[#0A2641] px-6 py-4 text-white rounded-lg">
                    Contact us
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M1 12.75H23M23 12.75L14.75 4.5M23 12.75L14.75 21"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            )}
            
          </div>
        
        </>
      )}
    </div>
  );
};

export default QualityAnalysis;
